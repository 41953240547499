<template>
  <TabContent :errorOccurred="errorOccurred">
    <section>
      <h1>Friends</h1>
      <h2>Incoming Friend Requests</h2>
      <InfiniteScroll
        :getFnc="getFriendRequestsReceivedFnc"
        :errorMessage="'Hrm, we couldn\'t load your pending friend requests...'"
        :emptyMessage="'You have no friend requests right now.'"
        @dataChange="handlePendingFriendRequestsChanged($event)"
        @errorOccurred="handleError()"
      >
        <transition-group name="friend">
          <FriendRequestChip
            v-for="request in pendingFriendRequests"
            :key="request.sender"
            :sender="request.sender"
            :receiver="request.receiver"
            :requestId="request.id"
            :rejected="false"
            @rejected="
              handlePendingRequestRejected(request.id, request.sender, request.received, true)
            "
            @accepted="handlePendingRequestAccepted(request.id, $event)"
          />
        </transition-group>
      </InfiniteScroll>

      <transition name="rejected-requests">
        <div v-show="showSentFriendRequests" class="sent-friend-requests-wrapper">
          <h2>Friend Requests Sent</h2>
          <InfiniteScroll
            :getFnc="getFriendRequestsSentByUser"
            :errorMessage="'Hrm, we couldn\'t load the friend requests you\'ve sent'"
            :emptyMessage="'You haven\'t sent any friend requests.'"
            @dataChange="handleSentFriendRequestsChanged($event)"
            @errorOccurred="handleError()"
          >
            <transition-group name="friend">
              <Chip v-for="request in sentFriendRequests" :key="request.receiver" class="sent-request">
                <span>{{ request.receiver }}</span>
              </Chip>
            </transition-group>
          </InfiniteScroll>
        </div>
      </transition>

      <transition name="rejected-requests">
        <div v-show="showRejectedFriendRequests" class="rejected-friend-requests-wrapper">
          <h2>Rejected Friend Requests</h2>
          <InfiniteScroll
            :getFnc="getFriendRequestsRejectedByUser"
            :errorMessage="'Hrm, we couldn\'t load your rejected friend requests...'"
            :emptyMessage="'You haven\'t rejected any friend requests.'"
            @dataChange="handleRejectedFriendRequestsChanged($event)"
            @errorOccurred="handleError()"
          >
            <transition-group name="friend">
              <FriendRequestChip
                v-for="request in rejectedFriendRequests"
                :key="request.sender"
                :sender="request.sender"
                :receiver="request.receiver"
                :requestId="request.id"
                :rejected="true"
                @accepted="handleRejectedRequestAccepted(request.id, $event)"
              />
            </transition-group>
          </InfiniteScroll>
        </div>
      </transition>

      <h2>Your Friends</h2>
      <!-- TODO: using InfiniteScroll for this is kind of a hack, since friends are not paginated
           on the backend right now. The 'resultProperty' prop is set to null, meaning that the
           entirety of the response object should be returned. This is janky and weird, and should
           be removed when the server issue is fixed-->
      <InfiniteScroll
        :getFnc="getFriendsFnc"
        :errorMessage="'Hrm, we couldn\'t load your friends. 😥'"
        :emptyMessage="'You have no friends yet. Now\'s the time to send some requests! 😎'"
        :resultProperty="null"
        @dataChange="handleFriendsChanged($event)"
        @errorOccurred="handleError()"
      >
        <transition-group name="friend">
          <Chip v-for="friend in friends" :key="friend.username" class="friend">
            <span>{{ friend.username }}</span>
            <ModalButton
              :onOkClicked="removeFriend.bind(this, $username, friend.username)"
              :successMessage="`'${friend.username}' was removed`"
              :ariaLabel="`Remove '${friend.username}'`"
              :buttonTitle="`Remove '${friend.username}'`"
              title="Are you sure?"
              @okSuccess="removeFriendFromList(friend.username)"
              >Remove {{ friend.username }}?
            </ModalButton>
          </Chip>
        </transition-group>
      </InfiniteScroll>
    </section>
  </TabContent>
</template>
<script>
import TabContent from '../components/TabContent.vue';
import InfiniteScroll from '../components/InfiniteScroll.vue';
import Chip from '../components/Chip.vue';
import ModalButton from '../components/ModalButton.vue';
import FriendRequestChip from '../components/FriendRequestChip.vue';
import {
  getFriendsForUser,
  getFriendRequestsReceivedByUser,
  removeFriend,
  getFriendRequestsRejectedByUser,
  getFriendRequestsSentByUser
} from '../services/friendsService';

export default {
  name: 'Friends',
  components: {
    TabContent,
    InfiniteScroll,
    Chip,
    ModalButton,
    FriendRequestChip,
  },
  data() {
    return {
      getFriendRequestsReceivedFnc: getFriendRequestsReceivedByUser,
      getFriendsFnc: getFriendsForUser,
      pendingFriendRequests: [],
      sentFriendRequests: [],
      friends: [],
      rejectedFriendRequests: [],
      errorOccurred: false,
      removeFriend,
      getFriendRequestsRejectedByUser,
      getFriendRequestsSentByUser,
    };
  },
  computed: {
    showRejectedFriendRequests() {
      return this.rejectedFriendRequests.length > 0;
    },
    showSentFriendRequests() {
      return this.sentFriendRequests.length > 0;
    }
  },
  methods: {
    handlePendingFriendRequestsChanged(pendingFriendRequests) {
      this.pendingFriendRequests = pendingFriendRequests;
    },
    handleRejectedFriendRequestsChanged(rejectedFriendRequests) {
      this.rejectedFriendRequests = rejectedFriendRequests;
    },
    handleSentFriendRequestsChanged(sentFriendRequests) {
      this.sentFriendRequests = sentFriendRequests;
    },
    handleFriendsChanged(friends) {
      this.friends = friends;
    },
    handleError() {
      this.errorOccurred = true;
    },
    removeFriendFromList(friendUsername) {
      this.friends = this.friends.filter(f => f.username !== friendUsername);
    },
    handlePendingRequestRejected(id, sender, receiver) {
      const rejectedRequest = {
        id,
        sender,
        receiver,
        rejected: true,
      };
      this.pendingFriendRequests = this.pendingFriendRequests.filter(r => r.id !== id);

      this.rejectedFriendRequests.push(rejectedRequest);
    },
    handlePendingRequestAccepted(id, friend) {
      this.pendingFriendRequests = this.pendingFriendRequests.filter(r => r.id !== id);

      this.friends.push(friend);
    },
    handleRejectedRequestAccepted(id, friend) {
      this.rejectedFriendRequests = this.rejectedFriendRequests.filter(r => r.id !== id);

      this.friends.push(friend);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  .friend {
    width: 100%;

    .modal-button-wrapper {
      margin-left: auto;
    }

    button {
      margin-left: auto;
      opacity: 0.5;
      transition: opacity 400ms ease;
      padding: 0;

      &:hover {
        opacity: 1;
      }

      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.friend-enter-active,
.friend-leave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.friend-enter-from,
.friend-leave-to {
  opacity: 0;
  transform: translateY(3rem);
}

.rejected-requests-enter-active,
.rejected-requests-leave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.rejected-requests-enter-from,
.rejected-requests-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
