<template>
  <TabContent>
    <InfiniteScroll
      :getFnc="getNotifications"
      :errorMessage="'Hrm, we couldn\'t load your notifications...'"
      :emptyMessage="'You have no notifications right now.'"
      @dataChange="handleNotificationsChanged($event)"
      @errorOccurred="handleError()"
    >
      <!--TODO: ID should be provided as a better key, but date works-->
      <Notification
        v-for="notification in notifications"
        :key="notification.date"
        :link="notification.link"
        :message="notification.message"
        :date="notification.date"
        :lighterTheme="true"
        @linkClicked="handleNotificationClicked(notification.link)"
      />
    </InfiniteScroll>
  </TabContent>
</template>

<script>
import TabContent from '../components/TabContent.vue';
import InfiniteScroll from '../components/InfiniteScroll.vue';
import Notification from '../components/Notification.vue';
import { getNotifications } from '../services/notificationsService';
import notificationStateStore from '../services/notificationStateStore';

export default {
  name: 'NotificationsView',
  components: {
    TabContent,
    InfiniteScroll,
    Notification,
  },
  data() {
    return {
      getNotifications,
      errorOccurred: false,
      notifications: [],
    };
  },
  methods: {
    handleError() {
      this.errorOccurred = true;
    },
    handleNotificationsChanged(notifications) {
      this.notifications = notifications;
      notificationStateStore.clearNotificationCount();
    },
    getFormattedDate(dateText) {
      const date = new Date(dateText);
      return date.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  background : var(--color-purple-darken-1);
  margin-bottom: 0;
  padding: 2rem;

  .time {
    color: white;
  }
}
</style>
