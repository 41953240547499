<template>
  <div class="chip">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Chip',
  functional: true
};
</script>

<style lang="scss" scoped>
.chip {
  display: flex;
  background-color: var(--color-purple-darken-1);
  padding: 1rem 2rem;
  font-size: 2rem;
  border-radius: 1.5rem;
  margin-bottom: 1rem;
  align-items: center;
}

@media (max-width: 650px) {
  .chip {
    font-size: 1.5rem;
  }
}

@media (max-width: 400px) {
  .chip {
    font-size: 1.2rem;
  }
}
</style>
