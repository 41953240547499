<template>
  <Chip :class="{ loading: loading }">
    <span>{{ sender }}</span>
    <IconButton
      v-if="!rejected"
      buttonTitle="Reject this friend request"
      @clicked="handleRejected()"
      :disabled="loading"
    />
    <IconButton
      buttonTitle="Accept this friend request"
      iconType="check-solid"
      @clicked="handleAccepted()"
      :disabled="loading"
    />
    <transition name="spinner">
      <div v-if="loading" class="spinner-wrapper">
        <SmallLoadingSpinner />
      </div>
    </transition>
  </Chip>
</template>

<script>
import { modifyFriendRequest, addFriend } from '../services/friendsService';
import { showError, showSuccess } from '../services/toastService';
import SmallLoadingSpinner from './SmallLoadingSpinner.vue';
import Chip from './Chip.vue';
import IconButton from './IconButton.vue';

export default {
  name: 'FriendRequestChip',
  components: {
    SmallLoadingSpinner,
    Chip,
    IconButton,
  },
  props: {
    sender: String,
    receiver: String,
    requestId: Number,
    rejected: Boolean,
    acceptButtonTitle: String,
    rejectButtonTitle: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  emits: ['accepted', 'rejected'],
  methods: {
    handleRejected() {
      this.loading = true;

      modifyFriendRequest(this.$username, {
        sender: this.sender,
        receiver: this.receiver,
        id: this.requestId,
        rejected: true,
      })
        .then(() => {
          this.$emit('rejected', this.id);
          showSuccess(`Friend request from '${this.sender}' rejected`);
        })
        .catch(error => {
          showError(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleAccepted() {
      this.loading = true;

      addFriend(this.$username, this.sender)
        .then(result => {
          this.$emit('accepted', result);
          showSuccess(`Friend request from '${this.sender}' accepted!`);
        })
        .catch(error => {
          showError(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  position: relative;
  overflow: hidden;

  .spinner-wrapper {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
  }
  button {
    transition: transform 400ms ease;
  }
  &.loading {
    button {
      transform: translateX(-2rem);
    }
  }
  button:first-of-type {
    margin-left: auto;
  }
}

.spinner-enter-active,
.spinner-leave-active {
  transition: opacity 400ms ease, transform 600ms ease;
}

.spinner-enter-from,
.spinner-leave-to {
  opacity: 0;
  transform: translateX(2rem);
}
</style>
